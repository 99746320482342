// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

// colors
$primary: #173a4d;
$additional: #748993;
$bg: #fafafa;
$grey: #e2e2e2;
$secondary: #4cc0df;
$highlight: rgba(76, 192, 223, 0.1);
$accent: #00ac84;
$info: #8e5ca0;
$third: rgba(76, 192, 223, 0.8);
$dark: #183b4e;
$positive: #21ba45;
$negative: #c10015;
$warning: #f2c037;

// spacing
$body-font-size: 16px;
$app-border-radius: 100px;
$space-1: 8px;
$space-2: ($space-1 * 2);
$space-3: ($space-1 * 3);
