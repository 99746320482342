@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 300;
  src: url('~assets/fonts/Sofia Pro Light Az.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  src: url('~assets/fonts/Sofia Pro Regular Az.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/Sofia Pro Medium Az.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 600;
  src: url('~assets/fonts/Sofia Pro Semi Bold Az.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 700;
  src: url('~assets/fonts/Sofia Pro Bold Az.otf') format('opentype');
}

body {
  background: $bg;
  font-family: 'Sofia Pro';
  color: $dark;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.app-input {
  width: 100%;
  min-width: 180px;
  max-width: 400px;
  &_pointer .q-field__control .q-field__native {
    cursor: pointer;
  }
  .q-field__control {
    background-color: $highlight;
    border-radius: $app-border-radius;
    padding-left: 14px;
    padding-right: 14px;
  }
  .q-field__label {
    color: $additional;
  }
  &.q-field--readonly .q-field__control:before {
    border: 1px solid $additional;
  }
}

.app-dialog {
  min-width: 350px;
}

.text-label {
  color: $additional;
}

#q-app {
  a {
    color: $additional;
    text-decoration: none;
  }

  .app-link {
    color: $dark;
    &:hover {
      transition: 0.3s;
      color: $additional;
    }
  }

  .q-checkbox__label {
    color: $additional;
  }

  .text-500 {
    font-weight: 500;
  }

  .text-600 {
    font-weight: 600;
  }

  .text-300 {
    font-weight: 300;
  }

  .text-md {
    font-size: $space-2;
    font-weight: 500;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-horizontal {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-bottom: $space-1;
  }

  .title {
    font-size: 22px;
    font-weight: 500;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .q-item .app-input {
    max-width: 500px;
  }

  .app-page {
    padding: $space-3;
  }

  .item-icon {
    border-radius: 50%;
    background-color: $additional;
    padding: 10px;
  }

  .drawer-item {
    border-radius: $app-border-radius;
    padding: 0;
    margin: $space-2;
    &.q-router-link--exact-active {
      background-color: $highlight;
    }
  }

  .drawer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    .logout {
      margin: 32px auto;
      border-radius: $app-border-radius;
    }
    .copryrights {
      font-size: 11px;
      margin: 22px 0;
    }
  }

  .auth-btn {
    text-transform: capitalize;
    width: 100%;
  }

  .initials .q-avatar__content {
    padding-top: 4px;
  }

  .btn-more {
    line-height: 1;
    font-weight: 400;
    padding: 0;
    &::v-deep {
      .q-btn__wrapper {
        padding: 0;
      }
    }
  }
  .pagination {
    background-color: #fff;
    border-radius: $app-border-radius;
    padding: $space-1;
    box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.04),
      0px 4px 15px rgba(0, 0, 0, 0.08);
  }
}

.not-available {
  font-size: 12px;
  color: $additional;
}

.assets-table {
  padding: 0;
  color: $dark;
  min-height: 350px;
  .table {
    position: relative;
  }
  .scroll-indicator {
    opacity: 0;
    height: 0;
  }
  tbody tr:last-of-type td {
    border-bottom: 1px solid $grey;
  }
  thead tr {
    background-color: #dff4f8;
    th {
      font-weight: 600;
      text-align: center;
      padding: 0 2px;
      position: sticky;
      top: 0;
      z-index: 100;
    }

    &:first-child th:first-child {
      text-align: left;
      position: sticky;
      left: 0;
      top: 0;
      padding: $space-1;
      padding-left: 32px;
      z-index: 200;
      background-color: #dff4f8;
    }

    &:first-child th:last-child {
      min-width: 320px;
    }

    &:first-child th:first-child {
      border-top-left-radius: $app-border-radius;
    }
    &:first-child th:last-child {
      border-top-right-radius: $app-border-radius;
    }
    &:last-child th:first-child {
      border-bottom-left-radius: $app-border-radius;
    }
    &:last-child th:last-child {
      border-bottom-right-radius: $app-border-radius;
    }
  }
  tr {
    border-bottom: 1px solid $additional;
    &:hover {
      .asset-name-wrapper {
        background: #fafafa;
      }
    }
  }
  .asset-name-wrapper {
    min-width: 120px;
    position: relative;
    background: #fff;
    height: 100%;
    vertical-align: middle;
  }
  .asset-name {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    white-space: normal;
    margin-left: 5px;
  }
  td {
    text-align: center;
    padding: $space-1 4px;
  }
  td:first-of-type {
    text-align: left;
    position: sticky;
    z-index: 100;
    left: 0;
  }
  .asset-number {
    margin-right: $space-1;
    border-radius: 50%;
    background-color: $secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 20px;
    height: 20px;
    background: #fff;
  }
  .bar {
    align-items: center;
    .legend__stack {
      display: none;
    }
    .item__stack {
      border-radius: $app-border-radius;
      height: 25px;
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
    .label__stack {
      top: -2px;
    }
    .percent__stack {
      z-index: 10;
    }
  }
}

.state-legend {
  display: flex;
  justify-content: center;
  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  .color {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-right: 2px;
    border: 1px solid $additional;
  }
  .label {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 10px;
  }
}

.loading-main {
  @media (min-width: $breakpoint-sm-max) {
    left: 300px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  will-change: opacity;
}

.tooltip-item {
  position: relative;
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }
}

.tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 4px;
  background-color: $grey;
  border-radius: 4px;
  &:after {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $grey;
  }
}
