@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 300;
  src: url("~assets/fonts/Sofia Pro Light Az.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  src: url("~assets/fonts/Sofia Pro Regular Az.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 500;
  src: url("~assets/fonts/Sofia Pro Medium Az.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 600;
  src: url("~assets/fonts/Sofia Pro Semi Bold Az.otf") format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 700;
  src: url("~assets/fonts/Sofia Pro Bold Az.otf") format("opentype");
}
body {
  background: #fafafa;
  font-family: "Sofia Pro";
  color: #183b4e;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.app-input {
  width: 100%;
  min-width: 180px;
  max-width: 400px;
}
.app-input_pointer .q-field__control .q-field__native {
  cursor: pointer;
}
.app-input .q-field__control {
  background-color: rgba(76, 192, 223, 0.1);
  border-radius: 100px;
  padding-left: 14px;
  padding-right: 14px;
}
.app-input .q-field__label {
  color: #748993;
}
.app-input.q-field--readonly .q-field__control:before {
  border: 1px solid #748993;
}

.app-dialog {
  min-width: 350px;
}

.text-label {
  color: #748993;
}

#q-app a {
  color: #748993;
  text-decoration: none;
}
#q-app .app-link {
  color: #183b4e;
}
#q-app .app-link:hover {
  transition: 0.3s;
  color: #748993;
}
#q-app .q-checkbox__label {
  color: #748993;
}
#q-app .text-500 {
  font-weight: 500;
}
#q-app .text-600 {
  font-weight: 600;
}
#q-app .text-300 {
  font-weight: 300;
}
#q-app .text-md {
  font-size: 16px;
  font-weight: 500;
}
#q-app .flex-wrap {
  flex-wrap: wrap;
}
#q-app .flex-horizontal {
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-bottom: 8px;
}
#q-app .title {
  font-size: 22px;
  font-weight: 500;
}
#q-app .subtitle {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}
#q-app .q-item .app-input {
  max-width: 500px;
}
#q-app .app-page {
  padding: 24px;
}
#q-app .item-icon {
  border-radius: 50%;
  background-color: #748993;
  padding: 10px;
}
#q-app .drawer-item {
  border-radius: 100px;
  padding: 0;
  margin: 16px;
}
#q-app .drawer-item.q-router-link--exact-active {
  background-color: rgba(76, 192, 223, 0.1);
}
#q-app .drawer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
#q-app .drawer-bottom .logout {
  margin: 32px auto;
  border-radius: 100px;
}
#q-app .drawer-bottom .copryrights {
  font-size: 11px;
  margin: 22px 0;
}
#q-app .auth-btn {
  text-transform: capitalize;
  width: 100%;
}
#q-app .initials .q-avatar__content {
  padding-top: 4px;
}
#q-app .btn-more {
  line-height: 1;
  font-weight: 400;
  padding: 0;
}
#q-app .btn-more::v-deep .q-btn__wrapper {
  padding: 0;
}
#q-app .pagination {
  background-color: #fff;
  border-radius: 100px;
  padding: 8px;
  box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.04), 0px 4px 15px rgba(0, 0, 0, 0.08);
}

.not-available {
  font-size: 12px;
  color: #748993;
}

.assets-table {
  padding: 0;
  color: #183b4e;
  min-height: 350px;
}
.assets-table .table {
  position: relative;
}
.assets-table .scroll-indicator {
  opacity: 0;
  height: 0;
}
.assets-table tbody tr:last-of-type td {
  border-bottom: 1px solid #e2e2e2;
}
.assets-table thead tr {
  background-color: #dff4f8;
}
.assets-table thead tr th {
  font-weight: 600;
  text-align: center;
  padding: 0 2px;
  position: sticky;
  top: 0;
  z-index: 100;
}
.assets-table thead tr:first-child th:first-child {
  text-align: left;
  position: sticky;
  left: 0;
  top: 0;
  padding: 8px;
  padding-left: 32px;
  z-index: 200;
  background-color: #dff4f8;
}
.assets-table thead tr:first-child th:last-child {
  min-width: 320px;
}
.assets-table thead tr:first-child th:first-child {
  border-top-left-radius: 100px;
}
.assets-table thead tr:first-child th:last-child {
  border-top-right-radius: 100px;
}
.assets-table thead tr:last-child th:first-child {
  border-bottom-left-radius: 100px;
}
.assets-table thead tr:last-child th:last-child {
  border-bottom-right-radius: 100px;
}
.assets-table tr {
  border-bottom: 1px solid #748993;
}
.assets-table tr:hover .asset-name-wrapper {
  background: #fafafa;
}
.assets-table .asset-name-wrapper {
  min-width: 120px;
  position: relative;
  background: #fff;
  height: 100%;
  vertical-align: middle;
}
.assets-table .asset-name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  white-space: normal;
  margin-left: 5px;
}
.assets-table td {
  text-align: center;
  padding: 8px 4px;
}
.assets-table td:first-of-type {
  text-align: left;
  position: sticky;
  z-index: 100;
  left: 0;
}
.assets-table .asset-number {
  margin-right: 8px;
  border-radius: 50%;
  background-color: #4cc0df;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 20px;
  height: 20px;
  background: #fff;
}
.assets-table .bar {
  align-items: center;
}
.assets-table .bar .legend__stack {
  display: none;
}
.assets-table .bar .item__stack {
  border-radius: 100px;
  height: 25px;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.assets-table .bar .label__stack {
  top: -2px;
}
.assets-table .bar .percent__stack {
  z-index: 10;
}

.state-legend {
  display: flex;
  justify-content: center;
}
.state-legend .legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.state-legend .color {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 2px;
  border: 1px solid #748993;
}
.state-legend .label {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 10px;
}

@media (min-width: 1023px) {
  .loading-main {
    left: 300px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  will-change: opacity;
}

.tooltip-item {
  position: relative;
}
.tooltip-item:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}

.tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 4px;
  background-color: #e2e2e2;
  border-radius: 4px;
}
.tooltip:after {
  position: absolute;
  content: "";
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #e2e2e2;
}